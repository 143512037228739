<template>
  <div class="turn-over-wager">
    <div class="turn-over-wager__img">
      <atomic-image
        :src="getContent(turnOverWagerContent?.currentLocaleData, turnOverWagerContent?.defaultLocaleData, 'image')"
      />
    </div>

    <div class="turn-over-wager__title">
      {{ getContent(turnOverWagerContent?.currentLocaleData, turnOverWagerContent?.defaultLocaleData, 'title') }}
    </div>

    <p class="turn-over-wager__description">
      {{ getContent(turnOverWagerContent?.currentLocaleData, turnOverWagerContent?.defaultLocaleData, 'description') }}
    </p>

    <div class="turn-over-wager__info">
      <div class="turn-over-wager__info-wager">
        <span class="turn-over-wager__info-wager-label">
          {{
            getContent(turnOverWagerContent?.currentLocaleData, turnOverWagerContent?.defaultLocaleData, 'wagerLabel')
          }}:
        </span>

        <span class="turn-over-wager__info-wager-value">
          {{ turnOverWagerData.turnOverWager }}
        </span>
      </div>

      <div class="turn-over-wager__info-progress">
        <div
          class="turn-over-wager__info-progress-filled"
          :data-progress="`${progress || 0}%`"
          :style="`--progress: ${progress || 0}%`"
        />
      </div>

      <div class="turn-over-wager__info-amount">
        <div>
          <span class="turn-over-wager__info-amount-label">
            {{
              getContent(
                turnOverWagerContent?.currentLocaleData,
                turnOverWagerContent?.defaultLocaleData,
                'placedLabel'
              )
            }}:
          </span>

          <span class="turn-over-wager__info-amount-value">{{ placedAmount }}</span>
        </div>

        <div>
          <span class="turn-over-wager__info-amount-label">
            {{
              getContent(
                turnOverWagerContent?.currentLocaleData,
                turnOverWagerContent?.defaultLocaleData,
                'totalLabel'
              )
            }}:
          </span>

          <span class="turn-over-wager__info-amount-value">{{ totalAmount }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { CITurnOverWagerModal } from '~/types';

  const { modalsList } = useModalStore();
  const turnOverWagerContentParams = {
    contentKey: 'modal-turn-over-wager',
    contentRoute: ['modals', modalsList['turn-over-wager'].content as string],
  };
  const { getContentData: getTurnOverWagerContentData } =
    useContentLogic<CITurnOverWagerModal>(turnOverWagerContentParams);
  const { data: turnOverWagerContent } = await useLazyAsyncData(getTurnOverWagerContentData);

  const { getContent, formatAmount } = useProjectMethods();
  const { currencies } = useGlobalStore();
  const riskStore = useRiskStore();
  const { turnOverWagerData } = storeToRefs(riskStore);

  const placedAmount = computed(() => {
    if (turnOverWagerData.value.turnOverWagerAmount > 0 && turnOverWagerData.value.currency) {
      const findCurrency = currencies.find(currency => currency.code === turnOverWagerData.value.currency);
      if (!findCurrency) return '';
      const wageredAmount = turnOverWagerData.value.total - turnOverWagerData.value.turnOverWagerAmount;
      const roundWageredAmount = Math.round(wageredAmount * findCurrency.subunitToUnit) / findCurrency.subunitToUnit;
      return formatAmount(turnOverWagerData.value.currency, roundWageredAmount);
    }
    return '';
  });

  const totalAmount = computed(() => {
    if (turnOverWagerData.value.total > 0 && turnOverWagerData.value.currency) {
      return formatAmount(turnOverWagerData.value.currency, turnOverWagerData.value.total);
    }
    return '';
  });

  const progress = computed(() => {
    const progressValue =
      ((turnOverWagerData.value.total - turnOverWagerData.value.turnOverWagerAmount) / turnOverWagerData.value.total) *
      100;
    return Math.round(progressValue * 100) / 100;
  });
</script>

<style src="~/assets/styles/components/wallet/turn-over-wager.scss" lang="scss" />
